<template>
    <div class="newsInfo" @click="goDetail">
        <auto-img class="logoImg" :src="info.img" mul="69.5%"></auto-img>

        <div class="info">
            <div class="name">
                {{ info.title }}
            </div>
            <div class="sub">
                {{ info.introduction }}
            </div>

            <div class="time">
                <span>{{ info.createdAt }}</span>
                <img src="../../assets/icon_more.png" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NewsGride',
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    methods: {
        goDetail() {
            this.$router.push('/brandNewsDetail?id=' + this.info.id);
        }
    }
};
</script>
<style lang="scss" scoped>
.newsInfo {
    cursor: pointer;
    background-color: #fff;
    &:hover {
        box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.08);
        .content {
            .name {
                color: #ffa526;
            }
        }
    }
}

.name {
    font-size: 16px;
    color: #292c33;
    line-height: 26px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sub {
    font-size: 13px;
    color: #878d99;
    line-height: 24px;
    margin-top: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 10px;
    min-height: 48px;
}

.info {
    padding: 14px 16px;
}

.tips {
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    line-height: 17px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.26);
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    white-space: nowrap;
}
.time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 0;
    border-top: 1px solid #f2f3f5;

    img {
        width: 20px;
        height: 20px;
        display: block;
    }

    span {
        font-size: 13px;
        color: rgba(172, 179, 191, 1);
        line-height: 24px;
    }
}
</style>
