<template>
    <div class="newsInfo" @click="goDetail">
        <el-image :src="info.img" fit="cover"></el-image>

        <div class="content">
            <div class="title">
                {{ info.title }}
            </div>

            <div class="time">{{ info.createdAt }}</div>
            <div class="sub">
                {{ info.introduction }}
            </div>

            <i class="el-icon-right"></i>
        </div>
    </div>
</template>
<script>
export default {
    name: 'NewsRow',
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    methods: {
        goDetail() {
            this.$router.push('/brandNewsDetail?id=' + this.info.id);
        }
    }
};
</script>
<style lang="scss" scoped>
.newsInfo {
    display: flex;
    background-color: #fff;
    cursor: pointer;
    transition: all ease-in-out 0.3s;

    &:hover {
        box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.08);
        .content {
            .title {
                font-size: 22px;
                color: #ffa526;
                line-height: 32px;
            }
        }
    }
    .el-image {
        width:6.17rem;
				height: 4.41rem;
				flex-shrink: 0;
    }

    .content {
        // width: 50%;
        box-sizing: border-box;
        padding: 0.4rem;
        position: relative;

        .title {
            font-size: 22px;
            color: #292c33;
            line-height: 32px;
            transition: all ease-in-out 0.3s;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }

        .time {
            font-size: 13px;
            color: #acb3bf;
            line-height: 24px;
            margin-top: 0.15rem;
        }

        .sub {
            font-size: 14px;
            color: #878d99;
            line-height: 26px;
            margin-top: 0.16rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }

        &::after {
            content: '';
            width: 1rem;
            height: 4px;
            background: #dcdfe6;
            position: absolute;
            left: 0.4rem;
            bottom: 0.47rem;
        }
    }
}

.el-icon-right {
    font-size: 32px;
    color: #ffa526;
    position: absolute;
    right: 0.4rem;
    bottom: 0.3rem;
}
</style>
