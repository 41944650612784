<template>
    <div class="News" v-loading="loading">
        <div class="mainNews" v-if="mainNews">
            <news-row :info="mainNews"></news-row>
        </div>

        <el-row :gutter="30">
            <el-col :xl="6" :lg="6" :md="8" :sm="8" :xs="8" v-for="item in showList" :key="item.id">
                <news-gride :info="item"></news-gride>
            </el-col>
        </el-row>

        <empty-page v-if="empty">
            <span slot="text">{{ $t('huan-mei-you-shang-chuan-xin-wen-o') }}</span>
        </empty-page>
    </div>
</template>

<script>
import NewsRow from '../../components/brand/NewsRow';
import NewsGride from '../../components/brand/NewsGride';
import pageableList from '../../mixins/pageableList';
export default {
    name: 'News',
    mixins: [pageableList],
    data() {
        return {
            url: '/information/all',
            formType: { body: 'json', size: 50 },
            pushList: true
        };
    },
    computed: {
        listQuery() {
            return {
                query: {
                    vendorInfoId: this.$route.query.id,
                    status: 'PASS',
                    del: false
                },
                sort: 'top,desc;createdAt,desc;sort,desc'
            };
        },
        routeQuery() {
            return {
                id: this.$route.query.id
            };
        },
        mainNews() {
            if (this.list.length > 0) {
                return this.list[0];
            } else {
                return null;
            }
        },
        showList() {
            const list = [...this.list];
            if (list.length > 1) {
                list.splice(0, 1);
                return list;
            } else {
                return this.list || [];
            }
        }
    },
    mounted() {
        this.$emit('setStore', this.$route.query.id);
        this.getData();
        window.addEventListener('scroll', this.scrollEvent);
    },
    methods: {
        scrollEvent() {
            if (
                document.documentElement.clientHeight + document.documentElement.scrollTop + 50 >=
                    document.documentElement.offsetHeight &&
                !this.loading &&
                !this.finish
            ) {
                this.page++;
                this.getData();
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollEvent);
    },
    components: {
        NewsRow,
        NewsGride
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.News {
    @include center-content(167);
    padding-bottom: 50px;
    min-height: calc(100vh - 605px - 100px);
    position: relative;
    box-sizing: border-box;
}
.mainNews {
    padding-top: 40px;
}
@media screen and (max-height: 1200px) {
    .News {
        min-height: calc(1200px - 605px - 100px);
    }
}
.el-row {
    padding-top: 15px;
    padding-bottom: 15px;
    .el-col {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}
</style>
